import { gql, useQuery } from "@apollo/client";
import { Popover, Transition } from "@headlessui/react";
import React, { Fragment, useContext, useState } from "react";
import { CollectionsContext } from "../../../lib/context/collections-context";
import { useNavigate } from "react-router-dom";

const GET_DIMENSIONS_QUERY = gql`
  query GetCollectionWiseDimensions {
    getCollectionWiseDimensions {
      collections
      dimensions
    }
  }
`;

type GetDimentionsQueryResponse = {
  getCollectionWiseDimensions: {
    collections: string;
    dimensions: string[];
  }[];
};

const Collections = () => {
  const { data: collectionWiseDimentions } =
    useQuery<GetDimentionsQueryResponse>(GET_DIMENSIONS_QUERY);

  const { setFilters } = useContext(CollectionsContext);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div className="">
      <Popover className="relative">
        {() => (
          <>
            <Popover.Button
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className={`
                text-black ${isOpen ? "" : "text-opacity-90"}
                group inline-flex items-center font-semibold md:text-xs lg:text-base outline-none`}
            >
              <span>Collections</span>
            </Popover.Button>
            <Transition
              as={Fragment}
              show={isOpen}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className="absolute left-1/2 z-50 mt-3 basis-full -translate-x-1/2 transform px-4 sm:px-0"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5 bg-white p-4">
                  <div className="bg-white p-4 flex gap-x-3">
                    {collectionWiseDimentions?.getCollectionWiseDimensions.map(
                      (c) => (
                        <div
                          className="flex flex-col gap-y-3 md:w-[120px] lg:w-[160px] items-center border-r border-gray-300 last:border-r-0"
                          key={c.collections}
                        >
                          <h4 className="font-semibold text-sm lg:text-base">
                            {c.collections}
                          </h4>
                          {c.dimensions.map((d) => (
                            <p
                              className="text-xs lg:text-sm font-medium cursor-pointer hover:underline hover:underline-offset-[3px]"
                              onClick={() => {
                                setFilters({
                                  collections: [c.collections.toUpperCase()],
                                  colors: [],
                                  finishes: [],
                                  sizes: [d],
                                  areasOfApplication: [],
                                  categories: [],
                                  patterns: [],
                                  series: [],
                                  subCategories: [],
                                });
                                navigate("/collections");
                                setIsOpen(false);
                              }}
                              key={`${c.collections}:${d}`}
                            >
                              {d}
                            </p>
                          ))}
                        </div>
                      )
                    )}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default Collections;
