import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import { OFFICE_LATITUDE, OFFICE_LONGITUDE } from "../../../lib/data";

const OfficeMap = () => {
  const [pinCoords, setPinCoords] = useState({
    lat: OFFICE_LATITUDE,
    lng: OFFICE_LONGITUDE,
  });

  const handleMapDrag = () => {
    setPinCoords({ lat: OFFICE_LATITUDE, lng: OFFICE_LONGITUDE });
  };

  return (
    <div className="h-[40vh] sm:h-[50vh] md:h-[60vh] -mx-4 sm:-mx-5">
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAboynVzLAs5zd6coAMwTYKTPMPtMao4VY" }}
        defaultCenter={{
          lat: OFFICE_LATITUDE,
          lng: OFFICE_LONGITUDE,
        }}
        defaultZoom={20}
        options={{
          fullscreenControl: false,
          keyboardShortcuts: false,
        }}
        onChange={handleMapDrag}
      >
        <Location lat={pinCoords.lat} lng={pinCoords.lng} />
      </GoogleMapReact>
    </div>
  );
};

const Location: React.FC<{ lng: number; lat: number }> = () => {
  return (
    <div className="h-7 w-7">
      <img src="images/common/pin.svg" alt="pin" className="w-full h-auto" />
    </div>
  );
};

export default OfficeMap;
