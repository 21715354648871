import React from "react";
import clsx from "clsx";

interface GeneralDescriptionTableProps {
  data: {
    title: string;
    content: string | undefined;
  }[];
}

function GeneralDescriptionTable({ data }: GeneralDescriptionTableProps) {
  return (
    <table className="table-auto w-full">
      <tbody className="text-sm">
        {data.map((item, index) => (
          <tr
            key={item.title + (item.content ?? "-")}
            className={clsx("group hover:bg-gray-100 first:border-t-0 border border-gray-300", {
              "bg-gray-100": index % 2,
            })}
          >
            <td className="border-r border-gray-300 px-6 py-3 text-sm font-normal text-gray-600 group-hover:text-gray-800">
              {item.title}
            </td>
            <td className="px-6 py-3 text-sm font-normal text-gray-900">
              {item.content ?? "-"}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default GeneralDescriptionTable;
