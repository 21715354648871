import React, { useState } from "react";
import Popup from "../../common/popup";
import TextField from "../../common/inputs/text-field";

const MASTER_CATALOGUE = "/catalogue/Master-Catalog.pdf";

const downloadPdf = (src: string, fileName: string) => {
  const link = document.createElement("a");
  link.href = src;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const CatalogueCard = () => {
  const [enablePopup, togglePopup] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = (e: any) => {
    e.preventDefault();

    // send request
    console.log({ email });

    downloadPdf(MASTER_CATALOGUE, "Master Catalogue.pdf");
    setEmail("");
    togglePopup(false);
  };

  return (
    <>
      <div className="w-full flex flex-col mb-5 drop-shadow-xl">
        <div
          className="border border-gray-300 flex justify-center items-center cursor-pointer rounded-lg px-3 py-3"
          onClick={() => togglePopup(true)}
        >
          <img
            src="/catalogue/catalogue-thumbnail.png"
            alt="product-thumbnail"
            className="object-contain hover:scale-[1.03] transition-all duration-500"
            width="300px"
            height="250px"
          />
        </div>
        <div className="pr-2 pl-[2px] flex flex-col items-center mt-2 sm:mt-3 space-y-2 sm:space-y-4">
          <p className="text-lg font-semibold">Master Catalogue</p>
        </div>
      </div>
      <Popup
        isDismissable
        isOpen={enablePopup}
        setIsOpen={togglePopup}
        title="Please enter your Email"
      >
        <form
          className="w-full grid grid-cols-2 gap-x-6 gap-y-4 mt-4"
          onSubmit={handleSubmit}
        >
          <div className="col-span-2">
            <TextField
              label="Email ID :"
              placeholder="Email"
              type="email"
              value={email ?? ""}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <button
            type="submit"
            className="px-3 py-3 text-[15px] col-span-2 text-white bg-primary leading-[18px] text-center rounded-[4px] outline-none"
          >
            Download
          </button>
        </form>
      </Popup>
    </>
  );
};

export default CatalogueCard;
