import { clsx } from "clsx";
import React, { useState } from "react";

interface ProductImagesProps {
  images: string[];
}

function ProductImages({ images }: ProductImagesProps) {
  const [activeImage, setActiveImage] = useState(images[0]);

  function handlePrevButton() {
    const currIdx = images.findIndex((val) => val === activeImage);

    if (currIdx === 0) {
      setActiveImage(images[images.length - 1]);
    } else {
      setActiveImage(images[(currIdx - 1) % images.length]);
    }
  }

  function handleNextButton() {
    const currIdx = images.findIndex((val) => val === activeImage);
    setActiveImage(images[(currIdx + 1) % images.length]);
  }

  return (
    <div className="flex flex-col md:flex-row-reverse md:gap-x-4 gap-y-4">
      <div className="w-full md:w-[85%] lg:w-[90%] h-[500px] sm:h-[500px] lg:h-[650px] flex justify-center items-center border rounded-md border-gray-300 relative drop-shadow-lg">
        <div
          className={`absolute left-1 z-10 top-1/2 opacity-75 hover:opacity-100 transform duration-500 -translate-y-1/2 hover:-translate-x-1`}
        >
          <button
            onClick={handlePrevButton}
            className="bg-white rounded-md p-2 mx-1"
          >
            <img
              src="/images/common/left-arrow.svg"
              alt="left"
              className="h-6 w-6"
            />
          </button>
        </div>

        {/* bigger image */}
        <img
          src={activeImage}
          alt="product_image"
          className="w-full h-auto max-h-[500px] sm:max-h-[500px] lg:max-h-[650px]"
        />

        <div
          className={`absolute right-1 top-1/2 opacity-75 hover:opacity-100 transform -translate-y-1/2 z-10 duration-500 hover:translate-x-1`}
        >
          <button
            onClick={handleNextButton}
            className="bg-white rounded-md p-2 mx-1"
          >
            <img
              src="/images/common/right-arrow.svg"
              alt="left"
              className="h-6 w-6"
            />
          </button>
        </div>
      </div>

      {/* list of images */}
      <div className="flex w-full md:w-[15%] lg:w-[10%] items-center md:flex-col gap-x-5 gap-y-4 flex-wrap">
        {images.map((item) => (
          <div
            className={clsx(
              "w-1/4 md:w-full max-w-[100px] h-[90px] flex justify-center items-center",
              {
                "border-[2.5px] border-primary rounded-sm":
                  activeImage === item,
              }
            )}
            key={item}
          >
            <img
              src={item}
              alt="list_image"
              className="w-full h-auto cursor-pointer max-h-[86px]"
              onClick={() => setActiveImage(item)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProductImages;
