import Layout from "../../modules/layout/templates";
import HomePageTemplate from "../../modules/home/templates";
import { S3_BUCKET_URI } from "../../lib/data";

const Home = () => {
  const GALLARY_IMAGE = S3_BUCKET_URI + "/static-images/cover_image.png";
  return (
    <Layout>
      <img
        className="w-full h-auto md:h-[93vh] object-cover drop-shadow-md sm:drop-shadow-xl"
        src={GALLARY_IMAGE}
        alt="img"
      />
      <div className="my-5 sm:my-10 lg:mx-[5%] font-poppins">
        <HomePageTemplate />
      </div>
    </Layout>
  );
};

export default Home;
