import React from "react";
import ProductCard from "../components/product-card";
import { ProductType } from "../../../../types";

type ProductsListProps = {
  products: ProductType[];
  noItemsText?: string;
};

const ProductsList: React.FC<ProductsListProps> = ({
  products,
  noItemsText,
}) => {
  return (
    <>
      {!!products.length ? (
        <div className="grid grid-cols-1 xsmall:grid-cols-2 md:grid-cols-3 gap-x-2 sm:gap-x-3 md:gap-x-4 gap-y-4 sm:gap-y-5">
          {products.map((p) => (
            <ProductCard product={p} key={p._id} />
          ))}
        </div>
      ) : (
        <div className="text-center w-full my-4">
          <p>{noItemsText ?? "No Products to display"}</p>
        </div>
      )}
    </>
  );
};

export default ProductsList;
