import React, { useContext } from "react";
import { CollectionsContext } from "../../../lib/context/collections-context";
import { useNavigate } from "react-router-dom";

const Finishes = () => {
  const {
    setFilters,
    pagination: { setCurrPage },
  } = useContext(CollectionsContext);
  const navigate = useNavigate();

  const handleCardClick = (type: string) => {
    setFilters({
      collections: [],
      colors: [],
      finishes: [type],
      sizes: [],
      areasOfApplication: [],
      categories: [],
      patterns: [],
      series: [],
      subCategories: [],
    });

    setCurrPage(1);
    navigate("/collections");
  };

  return (
    <div className="flex flex-col space-y-12 mt-14">
      <h2 className="text-[40px] text-[#080808] font-semibold text-center">
        Finishes
      </h2>
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-x-3 gap-y-5 sm:gap-y-7 md:gap-y-10">
        <Card
          src="images/homepage/finishes/1.svg"
          label="Glossy"
          onClick={() => handleCardClick("GLOSSY")}
        />
        <Card
          src="images/homepage/finishes/2.svg"
          label="Matt"
          onClick={() => handleCardClick("MATT")}
        />
        <Card
          src="images/homepage/finishes/3.svg"
          label="Rustic"
          onClick={() => handleCardClick("RUSTIC")}
        />
        <Card
          src="images/homepage/finishes/4.svg"
          label="Satin"
          onClick={() => handleCardClick("SATIN")}
        />
        <Card
          src="images/homepage/finishes/5.svg"
          label="High Glossy"
          onClick={() => handleCardClick("HIGH GLOSSY")}
        />
        <Card
          src="images/homepage/finishes/6.svg"
          label="Carving"
          onClick={() => handleCardClick("CARVING")}
        />
        <Card
          src="images/homepage/finishes/7.svg"
          label="Pearl"
          onClick={() => handleCardClick("Pearl")}
        />
        <Card
          src="images/homepage/finishes/8.svg"
          label="Frost"
          onClick={() => handleCardClick("FROST")}
        />
      </div>
    </div>
  );
};

const Card: React.FC<{ src: string; label: string; onClick: () => void }> = ({
  src,
  label,
  onClick,
}) => {
  return (
    <div
      className="col-span-1 flex justify-center items-center group max-w-[329px] h-[329px] bg-no-repeat cursor-pointer drop-shadow-lg"
      style={{
        backgroundImage: `url(${src})`,
      }}
      onClick={onClick}
    >
      <div className="w-full h-full group-hover:backdrop-grayscale-0 group-hover:bg-white/40 flex flex-col gap-y-1 justify-center items-center">
        <p className="opacity-0 group-hover:opacity-100 transition-opacity duration-500 font-semibold text-lg text-black rounded-lg px-4">
          {label}
        </p>
        <p className="underline underline-offset-2 text-primary mt-2 group-hover:mt-0 font-bold text-sm opacity-0 group-hover:opacity-100 transition-all duration-500 rounded-lg px-4">
          Explore Collection
        </p>
      </div>
    </div>
  );
};

export default Finishes;
