import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

import { CALL_US_CONTACT } from "../../../lib/data";
import { Link, useNavigate } from "react-router-dom";

type SidebarProps = {
  open: boolean;
  setOpen: (arg: boolean) => void;
};

const Sidebar: React.FC<SidebarProps> = ({ open, setOpen }) => {
  const navigate = useNavigate();

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50 lg:hidden" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900/80" />
        </Transition.Child>

        <div className="fixed inset-0 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button
                    type="button"
                    className="-m-2.5 p-2.5 outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <img
                      src="/images/common/close.svg"
                      alt="close"
                      className="h-6 w-6 text-white"
                    />
                  </button>
                </div>
              </Transition.Child>
              <div className="relative flex flex-col items-center gap-y-8 overflow-y-auto bg-primary-light px-4 py-4 grow pb-2">
                <div className="w-[70%] max-w-[245px]">
                  <img src={"/logo.svg"} alt="logo" className="w-full h-auto" />
                </div>

                <ul className="list-none text-lg font-bold space-y-5 flex flex-col items-center text-primary ">
                  <li className="cursor-pointer" onClick={() => navigate("/")}>
                    Home
                  </li>
                  <li
                    className="cursor-pointer"
                    onClick={() => navigate("/about-us")}
                  >
                    About Us
                  </li>
                  <li
                    className="cursor-pointer"
                    onClick={() => navigate("/collections")}
                  >
                    Collections
                  </li>
                  <li
                    className="cursor-pointer"
                    onClick={() => navigate("/export")}
                  >
                    Export
                  </li>
                  <li
                    className="cursor-pointer"
                    onClick={() => navigate("/catalogue")}
                  >
                    Catalogue
                  </li>
                  <li
                    className="cursor-pointer"
                    onClick={() => navigate("/contact-us")}
                  >
                    Contact Us
                  </li>
                </ul>

                <Link
                  to={`tel:+91${CALL_US_CONTACT.split("-").join("")}`}
                  target="_blank"
                  className="absolute bottom-3 font-semibold md:text-xs lg:text-base text-right text-primary"
                >
                  Call Us: {CALL_US_CONTACT}
                </Link>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Sidebar;
