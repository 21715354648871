import React, { useEffect } from "react";
import Layout from "../../modules/layout/templates";
import CollectionsTemplate from "../../modules/collections/templates";

const Collections = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Layout>
      <CollectionsTemplate />
    </Layout>
  );
};

export default Collections;
