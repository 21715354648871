import { useContext, useMemo } from "react";
import { Disclosure } from "@headlessui/react";
import {
  CollectionsContext,
  FiltersProps,
} from "../../../lib/context/collections-context";
import { gql, useQuery } from "@apollo/client";
import LoadingIndicator from "../../common/loading-indicator";
import { GetProductsQueryVariables } from "../templates";
import { COLLECTIONS_PAGINATION_LIMIT } from "../../../lib/data";

const GET_FILTERING_OPTIONS_QUERY = gql`
  query GetFilteringOptions {
    getFilteringOptions {
      collections
      colors
      sizes
      finishes
      areasOfApplication
      categories
      series
      patterns
      subCategories
    }
  }
`;

type GetFilteringOptionsQueryResponse = {
  getFilteringOptions: {
    collections: string[];
    colors: string[];
    sizes: string[];
    finishes: string[];
    areasOfApplication: string[];
    categories: string[];
    patterns: string[];
    series: string[];
    subCategories: string[];
  };
};

type SectionType = {
  id: string;
  name: string;
  options: {
    label: string;
    value: string;
    checked: boolean;
  }[];
};

const CollectionsFilters: React.FC<{
  onFilterChange: (
    variables?: Partial<GetProductsQueryVariables> | undefined
  ) => void;
}> = ({ onFilterChange }) => {
  const { data: filteringOptions, loading: loadingFilteringOptions } =
    useQuery<GetFilteringOptionsQueryResponse>(GET_FILTERING_OPTIONS_QUERY);

  const {
    filters: selectedFilters,
    setFilters: setSelectedFilters,
    pagination: { setCurrPage },
  } = useContext(CollectionsContext);

  const transformedFilters = useMemo(
    () =>
      transformFilters(
        selectedFilters,
        filteringOptions as { getFilteringOptions: FiltersProps }
      ),
    [selectedFilters, filteringOptions]
  );

  const handleChange = (
    section: SectionType,
    optionIdx: number,
    newVal: boolean
  ) => {
    // @ts-ignore
    const prevSelectedFields = new Set(selectedFilters[section.id]);
    if (newVal) {
      prevSelectedFields.add(section.options[optionIdx].value);
    } else {
      prevSelectedFields.delete(section.options[optionIdx].value);
    }

    const newFilters = {
      ...selectedFilters,
      [section.id]: Array.from(prevSelectedFields),
    };

    setSelectedFilters(newFilters);
    onFilterChange({
      filter: newFilters,
      limit: COLLECTIONS_PAGINATION_LIMIT,
      page: 1,
    });

    setCurrPage(1);
  };

  return (
    <div className="w-full sm:max-w-7xl">
      <div>
        <main className="w-full">
          {loadingFilteringOptions || !!!filteringOptions ? (
            <div className="mt-4">
              <LoadingIndicator size={25} />
            </div>
          ) : (
            <section aria-labelledby="products-heading" className="pb-24">
              <div className="grid gap-x-8 gap-y-10 grid-cols-4">
                {/* Filters */}
                <form className="col-span-4">
                  {transformedFilters.map((section: SectionType) => (
                    <Disclosure
                      as="div"
                      key={section.id}
                      className="border-b border-primary-light py-6"
                    >
                      {({ open }) => (
                        <>
                          <h3 className="-my-3 flow-root">
                            <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500">
                              <span className="font-medium text-gray-900 text-lg">
                                {section.name}
                              </span>
                              <span className="ml-6 flex items-center text-primary pr-1">
                                {open ? (
                                  <img
                                    src="/images/common/minus.png"
                                    className="h-4 w-4"
                                    alt="minus"
                                  />
                                ) : (
                                  <img
                                    src="/images/common/plus.png"
                                    className="h-4 w-4"
                                    alt="minus"
                                  />
                                )}
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="pt-6">
                            <div className="space-y-4 max-h-64 overflow-y-auto">
                              {section.options.map(
                                (option: any, optionIdx: number) => (
                                  <div
                                    key={option.value}
                                    className="flex items-center"
                                  >
                                    <input
                                      id={`filter-${section.id}-${optionIdx}`}
                                      name={`${section.id}[]`}
                                      type="checkbox"
                                      checked={option.checked}
                                      onChange={(e) =>
                                        handleChange(
                                          section,
                                          optionIdx,
                                          e.target.checked
                                        )
                                      }
                                      className="h-5 w-5 rounded accent-primary"
                                    />
                                    <label
                                      htmlFor={`filter-${section.id}-${optionIdx}`}
                                      className="ml-3 text-sm text-gray-800"
                                    >
                                      {option.label}
                                    </label>
                                  </div>
                                )
                              )}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </form>
              </div>
            </section>
          )}
        </main>
      </div>
    </div>
  );
};

const transformFilters = (
  selectedFilters: FiltersProps,
  filteringOptions: { getFilteringOptions: FiltersProps }
) => {
  const helper = (key: string) => {
    // @ts-ignore
    return filteringOptions.getFilteringOptions[key].map((c) => ({
      value: c,
      label: c,
      // @ts-ignore
      checked: selectedFilters[key].findIndex((sc) => sc === c) > -1,
    }));
  };

  if (filteringOptions) {
    const collections = helper("collections");
    const sizes = helper("sizes");
    const colors = helper("colors");
    const finishes = helper("finishes");
    const patterns = helper("patterns");
    const areasOfApplication = helper("areasOfApplication");
    const subCategories = helper("subCategories");
    const categories = helper("categories");
    const series = helper("series");

    return [
      {
        id: "collections",
        name: "Collections",
        options: collections,
      },
      {
        id: "categories",
        name: "Categories",
        options: categories,
      },
      {
        id: "subCategories",
        name: "Sub-categories",
        options: subCategories,
      },
      {
        id: "patterns",
        name: "Patterns",
        options: patterns,
      },
      {
        id: "series",
        name: "Series",
        options: series,
      },
      {
        id: "areasOfApplication",
        name: "Areas of application",
        options: areasOfApplication,
      },
      {
        id: "sizes",
        name: "Sizes",
        options: sizes,
      },
      {
        id: "finishes",
        name: "Finishes",
        options: finishes,
      },
      {
        id: "colors",
        name: "Colors",
        options: colors,
      },
    ];
  }
  return [
    {
      id: "collections",
      name: "Collections",
      options: [],
    },
    {
      id: "categories",
      name: "Categories",
      options: [],
    },
    {
      id: "subCategories",
      name: "Sub-categories",
      options: [],
    },
    {
      id: "patterns",
      name: "Patterns",
      options: [],
    },
    {
      id: "series",
      name: "Series",
      options: [],
    },
    {
      id: "areasOfApplication",
      name: "Areas of application",
      options: [],
    },
    {
      id: "sizes",
      name: "Sizes",
      options: [],
    },
    {
      id: "finishes",
      name: "Finishes",
      options: [],
    },
    {
      id: "colors",
      name: "Colors",
      options: [],
    },
  ];
};

export default CollectionsFilters;
