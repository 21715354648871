import React from "react";
import CatalogueList from "./catalogue-list";

const CatalogueTemplate = () => {
  return (
    <div className="flex flex-col justify-start pt-6 md:pt-14 px-4 sm:px-5 pb-12 lg:mx-[5%]">
      <h3 className="font-semibold text-3xl sm:text-4xl md:text-5xl mb-7 sm:mb-10">
        Catalogues
      </h3>
      <CatalogueList />
    </div>
  );
};

export default CatalogueTemplate;
