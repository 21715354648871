import React, { useState } from "react";
import TextField from "../../common/inputs/text-field";
import TextAreaField from "../../common/inputs/text-area";

const ExportInquiryForm = () => {
  const [formState, setFormState] = useState<Record<string, any>>({});
  const [successMsg, setSuccessMsg] = useState(false);

  const handleChange = (fieldName: string, value: any) => {
    if (successMsg) setSuccessMsg(false);

    setFormState((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log({ formState });
  };

  return (
    <form
      className="w-full sm:w-4/5 mx-auto grid grid-cols-2 gap-x-6 gap-y-4 mt-10"
      onSubmit={handleSubmit}
    >
      <div className="col-span-2 sm:col-span-1 grid grid-cols-1 gap-4">
        <div className="col-span-1">
          <TextField
            label="Name :"
            placeholder="Name"
            type="text"
            value={formState.name ?? ""}
            onChange={(e) => handleChange("name", e.target.value)}
            required
          />
        </div>
        <div className="col-span-1">
          <TextField
            label="Contact Number :"
            placeholder="Contact Number"
            type="number"
            value={formState.number ?? ""}
            onChange={(e) => handleChange("number", e.target.value)}
            required
          />
        </div>
        <div className="col-span-1">
          <TextField
            label="Email ID :"
            placeholder="Email"
            type="email"
            value={formState.email ?? ""}
            onChange={(e) => handleChange("email", e.target.value)}
            required
          />
        </div>

        <div className="sm:hidden col-span-1">
          <TextAreaField
            label="Enter Address"
            placeholder="Enter Address"
            rows={4}
            value={formState.address}
            onChange={(e) => handleChange("address", e.target.value)}
          />
        </div>
        <div className="sm:hidden col-span-1">
          <TextField
            label="Enter City :"
            placeholder="Enter City"
            type="text"
            value={formState.city ?? ""}
            onChange={(e) => handleChange("city", e.target.value)}
            required
          />
        </div>

        <div className="col-span-1">
          <TextField
            label="Enter Country :"
            placeholder="Enter Country"
            type="text"
            value={formState.country ?? ""}
            onChange={(e) => handleChange("country", e.target.value)}
            required
          />
        </div>

        <div className="col-span-1">
          <TextField
            label="Enter State :"
            placeholder="Enter State"
            type="text"
            value={formState.state ?? ""}
            onChange={(e) => handleChange("state", e.target.value)}
            required
          />
        </div>
      </div>
      <div className="hidden sm:grid col-span-2 sm:col-span-1 grid-cols-1 gap-[10px]">
        <div className="col-span-1">
          <TextAreaField
            label="Enter Address"
            placeholder="Enter Address"
            rows={12}
            value={formState.address}
            onChange={(e) => handleChange("address", e.target.value)}
          />
        </div>
        <div className="col-span-1">
          <TextField
            label="Enter City :"
            placeholder="Enter City"
            type="text"
            value={formState.city ?? ""}
            onChange={(e) => handleChange("city", e.target.value)}
            required
          />
        </div>
      </div>
      <div className="col-span-2">
        <TextAreaField
          placeholder="Enter Comments"
          label="Comments"
          value={formState.comments ?? ""}
          onChange={(e) => handleChange("comments", e.target.value)}
          rows={4}
        />
      </div>
      {successMsg && (
        <p className="col-span-2 text-xs sm:text-base text-green-400 font-medium">
          Your Response has been received.
        </p>
      )}
      <button
        type="submit"
        className="col-span-2 w-full sm:w-1/2 md:w-1/3 lg:w-1/6 px-2 py-3 text-[15px] text-white bg-primary leading-[18px] text-center rounded-[4px] outline-none uppercase"
      >
        Send
      </button>
    </form>
  );
};

export default ExportInquiryForm;
