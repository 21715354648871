import React from "react";
import Layout from "../../modules/layout/templates";
import ContactUsTemplate from "../../modules/contact-us/templates";

const ContactUs = () => {
  return (
    <Layout>
      <ContactUsTemplate />
    </Layout>
  );
};

export default ContactUs;
