import React from "react";
import Layout from "../../modules/layout/templates";
import ProductDetailsTemplate from "../../modules/product/templates";

const ProductDetails = () => {
  return (
    <Layout>
      <ProductDetailsTemplate />
    </Layout>
  );
};

export default ProductDetails;
