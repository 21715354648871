import clsx from "clsx";
import { CALL_US_CONTACT } from "../../../lib/data";
import { useState } from "react";
import Sidebar from "../component/mobile-sidebar";
import { useNavigate } from "react-router-dom";
import Collections from "../component/collections";

export const DesktopNavbar = () => {
  const navigate = useNavigate();

  return (
    <nav className="hidden md:block w-full bg-primary-light sticky top-0 z-50 drop-shadow-xl">
      <div className="flex justify-between items-center py-3 px-5">
        <div className="w-1/5 max-w-[245px]">
          <img
            src={"/logo.svg"}
            alt="logo"
            className="w-full h-auto cursor-pointer"
            onClick={() => navigate("/")}
          />
        </div>

        <div className="flex mx-3 md:space-x-7 lg:space-x-9 sm:space-x-5 justify-around items-center">
          <NavLink label="About Us" onClick={() => navigate("/about-us")} />
          <NavLink label="Export" onClick={() => navigate("/export")} />
          <Collections />
          <NavLink label="Catalogue" onClick={() => navigate("/catalogue")} />
          <NavLink label="Contact Us" onClick={() => navigate("/contact-us")} />
        </div>

        <div className="w-1/5">
          <p
            className="font-semibold md:text-xs lg:text-base text-right cursor-pointer"
            onClick={() => {
              window.open(
                `tel:+91${CALL_US_CONTACT.split("-").join("")}`,
                "_blank"
              );
            }}
          >
            Call us: {CALL_US_CONTACT}
          </p>
        </div>
      </div>
    </nav>
  );
};

type NavLinkProps = {
  label: string;
} & React.HTMLAttributes<HTMLDivElement>;

const NavLink: React.FC<NavLinkProps> = ({ label, className, ...props }) => {
  return (
    <div className={clsx("cursor-pointer", className)} {...props}>
      <p className="font-semibold md:text-xs lg:text-base">{label}</p>
    </div>
  );
};

export const MobileNavbar = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const navigate = useNavigate();

  return (
    <nav className="md:hidden w-full bg-primary-light overflow-hidden sticky top-0 z-50 drop-shadow-md">
      <div className="flex justify-between items-center py-3 px-5">
        <div className="w-1/2 sm:w-1/3 max-w-[245px]">
          <img
            src="/logo.svg"
            alt="logo"
            className="w-full h-auto cursor-pointer"
            onClick={() => navigate("/")}
          />
        </div>
        <img
          src={"/images/common/menu.svg"}
          alt="menu"
          className="h-[30px] w-[30px] cursor-pointer"
          onClick={() => setOpenSidebar(true)}
        />
      </div>
      <Sidebar open={openSidebar} setOpen={setOpenSidebar} />
    </nav>
  );
};
