import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./Home";
import AboutUs from "./AboutUs";
import Export from "./Export";
import ContactUs from "./ContactUs";
import ProductDetails from "./ProductDetails";
import PageNotFound from "./PageNotFound";
import Catalogue from "./Catalogue";
import Collections from "./Collections";

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/export" element={<Export />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/products/:productId" element={<ProductDetails />} />
        <Route path="/catalogue" element={<Catalogue />} />
        <Route path="/collections" element={<Collections />} />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}
