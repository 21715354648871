import React, { useContext } from "react";
import { CollectionsContext } from "../../../lib/context/collections-context";
import { useNavigate } from "react-router-dom";
import { S3_BUCKET_URI } from "../../../lib/data";

const Carousel = () => {
  const { setFilters } = useContext(CollectionsContext);
  const navigate = useNavigate();

  const handleCardClick = (collection: string) => {
    setFilters({
      collections: [collection],
      colors: [],
      finishes: [],
      sizes: [],
      areasOfApplication: [],
      categories: [],
      patterns: [],
      series: [],
      subCategories: [],
    });

    navigate("/collections");
  };

  const COLLECTIONS_BUCKET = S3_BUCKET_URI + "/static-images/collections";

  return (
    <div
      className="-mx-4 sm:-mx-5 h-full drop-shadow-lg"
      style={{
        backgroundImage: "url(images/homepage/carousel/bg.svg)",
      }}
    >
      <div className="backdrop-grayscale-0 bg-gray-800/70 w-full h-full flex flex-col justify-start text-white gap-y-1 px-5">
        <h4 className="font-medium text-base sm:text-lg pt-9">
          YOUR DREAM SPACE AWAITS
        </h4>
        <h2 className="font-semibold text-3xl sm:text-[38px] leading-10 sm:leading-[57px]">
          Professional Collections
        </h2>
        <div className="mt-6 sm:mt-8 flex gap-x-5 mb-7 overflow-x-scroll">
          <Card
            label="Jumbo(Slab)"
            src={COLLECTIONS_BUCKET + "/SLAB_JUMBO.png"}
            onClick={() => handleCardClick("SLABS")}
          />
          <Card
            label="Lucida(GVT)"
            src={COLLECTIONS_BUCKET + "/GVT_LUCIDA.png"}
            onClick={() => handleCardClick("VITRIFIED TILES")}
          />
          <Card
            label="Reflecto(DC)"
            src={COLLECTIONS_BUCKET + "/DC_REFLECTO.png"}
            onClick={() => handleCardClick("DOUBLE CHARGED")}
          />
          <Card
            label="Appollo(Ceramic)"
            src={COLLECTIONS_BUCKET + "/CERAMIC_APPOLO.png"}
            onClick={() => handleCardClick("CERAMIC TILES")}
          />
          <Card
            label="Durevole(FB)"
            src={COLLECTIONS_BUCKET + "/FB_DUREVOLE.png"}
            onClick={() => handleCardClick("FULL BODY")}
          />
        </div>
      </div>
    </div>
  );
};

const Card: React.FC<{ label: string; src: string; onClick: () => void }> = ({
  src,
  label,
  onClick,
}) => {
  return (
    <div
      className="flex flex-col gap-y-3 min-w-[250px] sm:min-w-[380px] max-w-[380px] w-1/4 cursor-pointer"
      onClick={onClick}
    >
      <img src={src} alt="img" className="h-[250px] sm:h-[380px] w-auto" />
      <p className="text-white font-medium text-base uppercase">{label}</p>
    </div>
  );
};

export default Carousel;
