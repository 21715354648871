import { ApolloClient, InMemoryCache } from "@apollo/client";

const cache = new InMemoryCache();

export const client = new ApolloClient({
  uri: process.env.REACT_APP_BACKEND,
  connectToDevTools: true,
  cache,
  defaultOptions: {
    mutate: {
      errorPolicy: "all",
    },
  },
});
