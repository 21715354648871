export const isObject = (obj: any) =>
  typeof obj === "object" && obj !== null && !Array.isArray(obj);

export function removeEmptyFields(obj: any) {
  if (isObject(obj)) {
    const newObj = JSON.parse(JSON.stringify(obj));
    for (const key in newObj) {
      if (newObj.hasOwnProperty(key)) {
        const value = newObj[key];

        if (
          value === null ||
          value === undefined ||
          value === "" ||
          (Array.isArray(value) && !value.length)
        ) {
          delete newObj[key];
        }
      }
    }
    return newObj;
  }
  return obj;
}

export const scrollToTop = () => {
  if (window) {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
};

export const textToPascalCase = (text: string) => {
  const splited = text.split(" ");
  return splited
    .map((val) => val[0].toUpperCase() + val.slice(1).toLowerCase())
    .join(" ");
};
