import React, { useState } from "react";
import TextField from "../../common/inputs/text-field";
import { gql, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";

const WHERE_TO_BUY_MUTATION = gql`
  mutation CreateWhereToBuy($input: WhereToBuyInput!) {
    createWhereToBuy(input: $input) {
      _id
      referenceId
      name
    }
  }
`;

type WhereToBuyMutationResponse = {
  createWhereToBuy: any;
};

type WhereToBuyMutationVariables = {
  input: {
    name: string;
    phone: string;
    email: string;
    pincode: string;
    quantity: number;
    product: string;
  };
};

const WhereToBuyForm = () => {
  const [formState, setFormState] = useState<Record<string, any>>({});
  const [successMsg, setSuccessMsg] = useState(false);
  const { productId = "" } = useParams<{ productId: string }>();

  const [createWhereToBuy, { loading }] = useMutation<
    WhereToBuyMutationResponse,
    WhereToBuyMutationVariables
  >(WHERE_TO_BUY_MUTATION);

  const handleChange = (fieldName: string, value: any) => {
    if (successMsg) setSuccessMsg(false);

    setFormState((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    createWhereToBuy({
      variables: {
        input: {
          name: formState.name,
          email: formState.email,
          quantity: formState.quantity,
          phone: formState.phone,
          pincode: formState.pincode,
          product: productId,
        },
      },
      onCompleted: (_) => setSuccessMsg(true),
    });
  };

  return (
    <form
      className="w-full grid grid-cols-2 gap-x-6 gap-y-4 mt-4"
      onSubmit={handleSubmit}
    >
      <div className="col-span-1">
        <TextField
          label="Your Name :"
          placeholder="Name"
          type="text"
          value={formState.name ?? ""}
          onChange={(e) => handleChange("name", e.target.value)}
          required
        />
      </div>

      <div className="col-span-1">
        <TextField
          label="Email ID :"
          placeholder="Email"
          type="email"
          value={formState.email ?? ""}
          onChange={(e) => handleChange("email", e.target.value)}
          required
        />
      </div>

      <div className="col-span-1">
        <TextField
          label="Your Phone :"
          placeholder="Phone"
          type="number"
          value={formState.phone ?? ""}
          onChange={(e) => handleChange("phone", e.target.value)}
          required
        />
      </div>

      <div className="col-span-1">
        <TextField
          label="Pincode :"
          placeholder="Pincode"
          type="number"
          value={formState.pincode ?? ""}
          onChange={(e) => handleChange("pincode", e.target.value)}
          required
        />
      </div>

      <CustomNumberInput
        state={formState.quantity ?? 0}
        setState={(val) => handleChange("quantity", val)}
      />

      {successMsg && (
        <p className="col-span-2 text-xs text-right text-green-500 font-medium">
          Your Response has been received.
        </p>
      )}

      <button
        type="submit"
        disabled={loading}
        className="col-span-2 px-2 py-3 text-[15px] text-white bg-primary disabled:bg-gray-400 leading-[18px] text-center rounded-[4px] outline-none ml-auto"
      >
        Submit
      </button>
    </form>
  );
};

const CustomNumberInput: React.FC<{
  state: number;
  setState: (arg: number) => void;
}> = ({ setState, state }) => {
  const decrement = () => {
    let newVal = state;

    if (newVal !== 0) setState(newVal - 1);
  };

  const increment = () => {
    setState(state + 1);
  };

  const handleChange = (e: any) => {
    let newVal = +e.target.value;

    if (newVal < 0) newVal = 0;

    setState(Math.floor(newVal));
  };

  return (
    <div className="col-span-2 w-32 space-y-2">
      <label
        htmlFor="custom-input-number"
        className="w-full text-sm sm:text-base font-semibold leading-[19px]"
      >
        Quantity
      </label>
      <div className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
        <button
          onClick={decrement}
          type="button"
          className="bg-primary text-white h-full w-20 rounded-l cursor-pointer outline-none"
          data-action="decrement"
        >
          <span className="m-auto text-2xl font-normal">−</span>
        </button>
        <input
          type="number"
          className="outline-none focus:outline-none text-center w-full bg-primary-light/40 font-semibold border-t border-b border-gray-500 text-md hover:text-black focus:text-black md:text-base cursor-default flex items-center text-gray-700"
          name="custom-input-number"
          value={state}
          onChange={handleChange}
        />
        <button
          onClick={increment}
          type="button"
          className="bg-primary text-white h-full w-20 cursor-pointer outline-none rounded-r"
          data-action="increment"
        >
          <span className="m-auto text-2xl font-normal">+</span>
        </button>
      </div>
    </div>
  );
};

export default WhereToBuyForm;
