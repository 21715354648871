import React, { useMemo, useState } from "react";
import Popup from "../../common/popup";
import WhereToBuyForm from "./where-to-buy-form";
import { ProductType } from "../../../types";
import { textToPascalCase } from "../../../lib/common";

const ProductInfo: React.FC<{ product: ProductType }> = ({ product }) => {
  const [openPopup, togglePopup] = useState(false);

  const firstInfoArr = useMemo(() => {
    return [
      {
        title: "Category",
        value: product.category,
      },
      {
        title: "Sub-category",
        value: product.subCategory,
      },
      {
        title: "Area of application",
        value: product.areaOfApplication,
      },
      {
        title: "Series",
        value: product.series,
      },
      {
        title: "Finish",
        value: product.finish,
      },
      {
        title: "Color family",
        value: product.colorFamily,
      },
      {
        title: "Pattern",
        value: product.pattern,
      },
    ];
  }, [product]);

  const middleInfoArr = useMemo(
    () => [
      {
        title: "Length",
        value: product.length + "mm",
      },
      {
        title: "Width",
        value: product.width + "mm",
      },
      {
        title: "Thickness",
        value: product.thickness + "mm",
      },
    ],
    [product]
  );

  const finalInfoArr = useMemo(
    () => [
      {
        title: "Number of Tiles per box",
        value: product.numberOfPiecesPerBox,
      },
      {
        title: "Coverage per box",
        value: product.coverage + "sqft",
      },
      {
        title: "Weight per box",
        value: product.weightPerBox + "kg",
      },
    ],
    [product]
  );

  const RenderInfo = ({ title, value }: { title: string; value: any }) => (
    <div className="flex space-x-2 items-center">
      <p className="text-base font-medium">{title} :</p>
      <p className="">{value ?? "Not Available"}</p>
    </div>
  );

  return (
    <div className="flex flex-col md:max-w-[40%] lg:max-w-[50%] space-y-5">
      <div className="flex flex-col gap-y-[8px] flex-wrap">
        <h2 className="font-medium sm:font-semibold text-2xl leading-10 md:text-[34px] sm:leading-[51px]">
          {textToPascalCase(product.nameOfTheTile)}
        </h2>

        <p className="text-base uppercase text-gray-400 font-medium">
          {product.shortDescription}
        </p>

        {firstInfoArr.map((item) => (
          <RenderInfo key={item.title} {...item} />
        ))}
      </div>

      <RenderInfo title="Code" value={product.homeAlankarCode} />

      <div className="flex flex-col gap-y-[8px] flex-wrap">
        {middleInfoArr.map((item) => (
          <RenderInfo key={item.title} {...item} />
        ))}
      </div>

      <div className="flex flex-col gap-y-[8px] flex-wrap">
        {finalInfoArr.map((item) => (
          <RenderInfo key={item.title} {...item} />
        ))}
      </div>

      <div className="w-full col-span-2">
        <button
          type="submit"
          className="px-4 py-3 text-[15px] text-white bg-primary drop-shadow-md leading-[18px] text-center rounded-[4px] font-medium outline-none"
          onClick={() => togglePopup(true)}
        >
          Inquiry Now
        </button>
      </div>
      <Popup
        isDismissable
        isOpen={openPopup}
        setIsOpen={togglePopup}
        title="Inquiry"
      >
        <WhereToBuyForm />
      </Popup>
    </div>
  );
};

export default ProductInfo;
