import { useNavigate } from "react-router-dom";
import { ProductType } from "../../../../types";

const ProductCard: React.FC<{ product: ProductType }> = ({ product }) => {
  const navigate = useNavigate();

  return (
    <div className="w-full flex flex-col mb-5">
      <div
        className="flex justify-center items-center cursor-pointer rounded-lg h-[300px] md:h-[250px] lg:h-[390px] border border-gray-300 w-full max-w-[430px] mx-auto"
        onClick={() => navigate(`/products/${product._id}`)}
      >
        <img
          src={product.imagePath}
          alt="product-thumbnail"
          className="transition-all duration-300 hover:scale-[1.02] drop-shadow-xl w-full h-auto max-h-[300px] md:max-h-[250px] lg:max-h-[390px] max-w-[430px]"
        />
      </div>
      <div className="pr-2 pl-[2px] flex flex-col mt-2 sm:mt-3 items-center text-center">
        <p className="text-base sm:text-lg font-semibold">
          {product.nameOfTheTile}
        </p>
        <p className="text-sm font-medium text-gray-600">
          {`${product.width} x ${product.length}mm`}
        </p>
      </div>
    </div>
  );
};

export default ProductCard;
