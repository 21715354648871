import React from "react";
import Layout from "../../modules/layout/templates";
import AboutUsTemplate from "../../modules/about-us/templates";

const AboutUs = () => {
  return (
    <Layout>
      <AboutUsTemplate />
    </Layout>
  );
};

export default AboutUs;
