import React from "react";
import Layout from "../../modules/layout/templates";
import ExportTemplate from "../../modules/export/templates";

const Export = () => {
  return (
    <Layout>
      <ExportTemplate />
    </Layout>
  );
};

export default Export;
