import clsx from "clsx";
import React, { useEffect, useRef } from "react";

interface PopupProps {
  title: string;
  subtitle?: string;
  isDismissable: boolean;
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
  higherIndex?: boolean;
  children: React.ReactElement;
}

const Popup = ({
  isOpen,
  setIsOpen,
  isDismissable,
  title,
  subtitle,
  children,
  higherIndex,
}: PopupProps) => {
  const ref = useRef<any>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [setIsOpen]);

  return isOpen ? (
    <div className="relative">
      <div
        className={clsx(
          "fixed top-0 left-0 w-full h-full bg-black bg-opacity-60 z-50",
          {
            "z-[60]": higherIndex,
          }
        )}
      >
        <div className="fixed bottom-0 sm:bottom-auto sm:top-1/2 sm:left-1/2 transform sm:-translate-x-1/2 sm:-translate-y-1/2 bg-[#e8e8e8] w-full sm:w-[60%] md:w-[50%] max-w-[639px] md:max-w-[50%] lg:max-w-[30%]">
          <div ref={ref} className="px-4 md:px-6 py-4 md:py-6 flex flex-col">
            <div
              className={clsx("flex", {
                "justify-center": !isDismissable,
                "justify-between": isDismissable,
              })}
            >
              <h3 className="text-xl font-bold leading-9 text-grey-900">
                {title}
              </h3>
              <div
                onClick={() => setIsOpen(false)}
                className={clsx(
                  "cursor-pointer self-start bg-grey-100 p-2 flex justify-center items-center rounded-4",
                  { hidden: !isDismissable }
                )}
              >
                <img
                  src="/images/common/close-black.svg"
                  alt="close"
                  className="h-5 w-5"
                />
              </div>
            </div>
            {subtitle && subtitle.length > 0 && (
              <p
                className={clsx(
                  "text-xs font-bold text-[#848484] leading-[22px] mt-[5px]",
                  {
                    "place-self-center": !isDismissable,
                  }
                )}
              >
                {subtitle}
              </p>
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Popup;
