import clsx from "clsx";
import React from "react";

const ResponsiveImage: React.FC<{
  maxSize: string;
  src: string;
  alt: string;
  dynamicWidth: string;
}> = ({ maxSize, src, alt, dynamicWidth }) => {
  return (
    <div className={clsx(`max-w-[${maxSize}] w-[${dynamicWidth}]`)}>
      <img src={src} alt={alt} />
    </div>
  );
};

export default ResponsiveImage;
