import { clsx } from "clsx";

type BreadcrumbsProps = {
  pages: {
    name: string;
    onClick: () => void;
    current: boolean;
  }[];
};

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ pages }) => {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-1 sm:space-x-4">
        <li>
          <div>
            <a
              href="/"
              className="text-sm font-medium text-gray-700 hover:text-gray-900"
            >
              Home
              <span className="sr-only">Home</span>
            </a>
          </div>
        </li>
        {pages.map((page) => (
          <li key={page.name}>
            <div className="flex items-center">
              <svg
                className="h-5 w-5 flex-shrink-0 text-gray-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              <p
                className={clsx(
                  "ml-1 sm:ml-4 text-sm font-medium text-gray-700 hover:text-gray-900 cursor-pointer",
                  {
                    "text-primary hover:text-primary": !!page.current,
                  }
                )}
                aria-current={page.current ? "page" : undefined}
                onClick={page.onClick}
              >
                {page.name}
              </p>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
