import React from "react";
import ResponsiveImage from "../../common/responsive-image";
import clsx from "clsx";

const CraftmanshipValueCard: React.FC<{
  label: string;
  src: string;
  isLast?: boolean;
}> = ({ src, label, isLast = false }) => {
  return (
    <div
      className={clsx(
        "col-span-1 flex items-center py-3 flex-col space-y-3 border-r border-[#373E51]",
        {
          "border-r-0": isLast,
        }
      )}
    >
      <ResponsiveImage
        alt="image"
        dynamicWidth="30%"
        maxSize="80px"
        src={src}
      />
      <p className="text-base font-medium text-center">{label}</p>
    </div>
  );
};

export default CraftmanshipValueCard;
