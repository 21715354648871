import React from "react";
import ExportInquiryForm from "../components/export-inquiry";
import VideoPlayer from "../../common/video-player";
import {
  VANSH_CERAMICS_DESKTOP_VIDEO,
  VANSH_CERAMICS_MOBILE_VIDEO,
  VANSH_CERAMICS_VIDEO_THUMBNAIL,
} from "../../../lib/data";
import useCurrentWidth from "../../../lib/hooks/useCurrentWidth";

const ExportTemplate = () => {
  const currentWidth = useCurrentWidth();

  return (
    <div className="flex flex-col justify-start pt-6 md:pt-14 px-4 sm:px-5 pb-12 lg:mx-[5%]">
      <h1 className="font-semibold text-4xl sm:text-5xl lg:text-7xl">Export</h1>
      <div className="grid grid-cols-2 sm:gap-x-7 gap-y-5 mt-7 sm:mt-11 md:mt-14 lg:mt-16">
        <div className="col-span-2 md:col-span-1 w-full max-w-[794px]">
          <img
            src="images/exportpage/1.svg"
            alt="about us img"
            className="w-full h-auto"
          />
        </div>
        <div className="col-span-2 md:col-span-1 mt-7 sm:mt-10 md:mt-16 flex flex-col space-y-3 sm:space-y-4">
          <p className="text-[#3B3A3A] text-base font-semibold">
            Elevate Your Spaces
          </p>
          <h4 className="font-medium text-3xl sm:text-[40px] leading-10 sm:leading-[60px]">
            Global Standards of Excellence in Every Export
          </h4>
          <p className="text-base">
            At our Marble & Tiles Company, we have been a trusted name in the
            industry for many years. We specialize in providing top-notch marble
            and tile solutions for homeowners, contractors, and designers. Our
            commitment to excellence starts from the moment you step into our
            showroom or browse our online catalog. With a wide range of
            high-quality products, personalized services, and a team of
            knowledgeable professionals, we are here to make your home
            transformation dreams a reality.
          </p>
        </div>
      </div>
      <div className="flex mt-16 sm:mt-24 flex-col">
        <div className="flex flex-col items-center mb-9">
          <p className="uppercase mb-1 sm:mb-0 font-medium text-base sm:text-lg text-[#676767] text-center">
            IN MORE THAN 70 NATIONS
          </p>
          <h4 className="font-semibold text-3xl sm:text-[40px] sm:leading-[60px] text-[#080808]">
            A Global Presence
          </h4>
        </div>
        <div className="-mx-4 sm:-mx-5">
          <img
            src="images/exportpage/2.svg"
            alt="img"
            className="w-full h-auto"
          />
        </div>
      </div>

      <div className="mt-10 sm:my-14 md:my-20 w-full md:w-[70%] mx-auto border">
        <VideoPlayer
          videoSrc={
            currentWidth < 768
              ? VANSH_CERAMICS_MOBILE_VIDEO
              : VANSH_CERAMICS_DESKTOP_VIDEO
          }
          thumbnailSrc={VANSH_CERAMICS_VIDEO_THUMBNAIL}
        />
      </div>

      {/* form */}
      <div className="flex mt-12 md:mt-16 lg:mt-0 flex-col">
        <h4 className="text-center font-semibold text-3xl sm:text-[40px] sm:leading-[60px] text-[#080808]">
          Export Enquiry
        </h4>
        <ExportInquiryForm />
      </div>
    </div>
  );
};

export default ExportTemplate;
