import React, { useState } from "react";
import ResponsiveImage from "../../common/responsive-image";
import { Link, useNavigate } from "react-router-dom";
import {
  ADDRESS_DETAILS,
  CALL_US_CONTACT,
  OFFICE_LATITUDE,
  OFFICE_LONGITUDE,
  SUPPORT_EMAIL_ADDRESS,
} from "../../../lib/data";
import { gql, useMutation } from "@apollo/client";

const CREATE_INSIGHT_MUTATION = gql`
  mutation CreateInsight($input: InsightInput!) {
    createInsight(input: $input) {
      referenceId
    }
  }
`;

type CreateInsightMutationVariables = {
  input: {
    email: string;
  };
};

const Footer = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [successMsg, setSuccessMsg] = useState(false);

  const [createInsight, { loading: creatingInsightSubscription }] = useMutation<
    {},
    CreateInsightMutationVariables
  >(CREATE_INSIGHT_MUTATION);

  const handleInsightSubscription = (e: any) => {
    e.preventDefault();

    createInsight({
      variables: {
        input: {
          email,
        },
      },
      onCompleted: (_) => setSuccessMsg(true),
    });
  };

  return (
    <div className="min-h-[416px] bg-primary-light w-full px-4 py-10 flex flex-col items-start md:flex-row justify-between md:space-x-4 space-y-6 md:space-y-0">
      {/* logo and social media links */}
      <div className="flex flex-col space-y-6 md:space-y-9 items-start md:items-center">
        <div className="w-full max-w-[329px]">
          <img src="/logo.svg" alt="logo" className="w-full h-auto" />
        </div>
        <div className="flex space-x-5 items-center">
          <Link to={"https://www.facebook.com/vanshceramics"} target="_blank">
            <ResponsiveImage
              src="/images/common/facebook.svg"
              alt="facebook"
              dynamicWidth={"24%"}
              maxSize={"30px"}
            />
          </Link>
          <Link to="https://twitter.com/VanshCeramics" target="_blank">
            <ResponsiveImage
              src="/images/common/x.svg"
              alt="X"
              dynamicWidth={"24%"}
              maxSize={"25px"}
            />
          </Link>
          <Link
            to="https://www.youtube.com/channel/UCyZATXz8U2FKf13RDgTjJiw"
            target="_blank"
          >
            <ResponsiveImage
              src="/images/common/youtube.svg"
              alt="youtube"
              dynamicWidth={"24%"}
              maxSize={"30px"}
            />
          </Link>
          <Link
            to="https://www.linkedin.com/company/vansh-ceramics/about/?viewAsMember=true"
            target="_blank"
          >
            <ResponsiveImage
              src="/images/common/linkedin.svg"
              alt="linkedin"
              dynamicWidth={"24%"}
              maxSize={"30px"}
            />
          </Link>
          <Link to="https://www.instagram.com/vanshceramics/" target="_blank">
            <img
              src="/images/common/instagram.png"
              alt="instagram"
              height={"27px"}
              width={"27px"}
            />
          </Link>
        </div>
      </div>

      {/* interlinks */}
      <ul className="list-none text-base font-medium space-y-3 md:space-y-5">
        <li className="cursor-pointer" onClick={() => navigate("/")}>
          Home
        </li>
        <li className="cursor-pointer" onClick={() => navigate("/about-us")}>
          About Us
        </li>
        <li className="cursor-pointer" onClick={() => navigate("/collections")}>
          Collections
        </li>
        <li className="cursor-pointer" onClick={() => navigate("/export")}>
          Export
        </li>
        <li className="cursor-pointer" onClick={() => navigate("/catalogue")}>
          Catalogue
        </li>
        <li className="cursor-pointer" onClick={() => navigate("/contact-us")}>
          Contact Us
        </li>
      </ul>

      {/* location and support */}
      <div className="flex flex-col space-y-3 md:space-y-5">
        <div className="flex space-x-3 text-base font-medium">
          <img
            src="/images/common/call.svg"
            alt="Call"
            className="cursor-pointer"
            onClick={() =>
              window.open(
                `tel:+91${CALL_US_CONTACT.split("-").join("")}`,
                "_blank"
              )
            }
          />
          <p>{CALL_US_CONTACT}</p>
        </div>
        <div className="flex space-x-3 items-start text-base font-medium">
          <img
            src="/images/common/location.svg"
            alt="Location"
            className="cursor-pointer"
            onClick={() => {
              const url = `https://www.google.com/maps?q=${OFFICE_LATITUDE},${OFFICE_LONGITUDE}`;
              window.open(url, "_blank");
            }}
          />
          <p className="max-w-[305px] text-base">{ADDRESS_DETAILS}</p>
        </div>
        <div className="flex space-x-3 text-base font-medium">
          <img
            src="/images/common/mail.svg"
            alt="Email"
            className="cursor-pointer"
            onClick={() =>
              window.open(`mailto:${SUPPORT_EMAIL_ADDRESS}`, "_blank")
            }
          />
          <p className="">{SUPPORT_EMAIL_ADDRESS}</p>
        </div>
      </div>

      {/* insights */}
      <div className="flex flex-col space-y-3 md:space-y-5">
        <h4 className="text-xl font-medium">Innovate Insights</h4>
        <p className="max-w-[312px] text-xs leading-5">
          Stay ahead of the curve with our "Innovate Insights" newsletter, your
          go-to source for the latest product updates, exclusive offers, and
          insightful content.
        </p>
        <div className="flex flex-col">
          <form
            className="flex space-x-2 items-center"
            onSubmit={handleInsightSubscription}
          >
            <div className="relative rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <img src="/images/common/mail.svg" alt="mail" />
              </div>
              <input
                type="email"
                name="email"
                required
                id="email"
                className="block w-full rounded-md border border-gray-500 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-primary-light placeholder:text-gray-600 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 bg-primary-light/90 focus:ring-primary outline-none"
                placeholder="you@gmail.com"
                value={email}
                onChange={(e) => {
                  if (successMsg) setSuccessMsg(false);
                  setEmail(e.target.value);
                }}
              />
            </div>
            <button
              type="submit"
              className="inline-flex items-center gap-x-1.5 rounded-md bg-black disabled:bg-gray-400 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              disabled={creatingInsightSubscription}
            >
              <img
                src="/images/common/forward.svg"
                alt="mail"
                className="-mr-0.5 h-6 w-6"
              />
            </button>
          </form>
          {successMsg && (
            <p className="col-span-2 text-xs text-green-700 font-medium mt-2">
              Your have subscribed to our innovate insights.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Footer;
