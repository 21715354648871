import React from "react";
import Layout from "../../modules/layout/templates";

const PageNotFound = () => {
  return (
    <Layout>
      <div className="flex flex-col space-y-3 h-[100vh] justify-center items-center px-4">
        <h2 className="font-semibold text-xl sm:text-2xl text-center">
          The page you are looking for doesn't exist
        </h2>
        <p className="text-base sm:text-lg text-gray-600 font-medium text-center">
          You may have mistyped the address or the page may have moved.
        </p>
      </div>
    </Layout>
  );
};

export default PageNotFound;
