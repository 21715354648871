import React, { useState } from "react";

export type FiltersProps = {
  collections: string[];
  colors: string[];
  sizes: string[];
  finishes: string[];
  series: string[];
  categories: string[];
  subCategories: string[];
  areasOfApplication: string[];
  patterns: string[];
};

type CollectionsProps = {
  filters: FiltersProps;
  setFilters: (arg: FiltersProps) => void;
  resetFilters: () => void;
  pagination: {
    currPage: number;
    setCurrPage: (arg: number) => void;
  };
};

export const CollectionsContext = React.createContext<CollectionsProps>({
  filters: {
    collections: [],
    colors: [],
    sizes: [],
    finishes: [],
    areasOfApplication: [],
    categories: [],
    subCategories: [],
    patterns: [],
    series: [],
  },
  setFilters: () => {},
  resetFilters: () => {},
  pagination: {
    currPage: 1,
    setCurrPage: () => {},
  },
});

export const CollectionsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [filters, setFilters] = useState<FiltersProps>({
    collections: [],
    colors: [],
    finishes: [],
    sizes: [],
    areasOfApplication: [],
    categories: [],
    patterns: [],
    series: [],
    subCategories: [],
  });

  const [currPage, setCurrPage] = useState<number>(1);

  const resetFilters = () => {
    setFilters({
      collections: [],
      colors: [],
      finishes: [],
      sizes: [],
      areasOfApplication: [],
      categories: [],
      patterns: [],
      series: [],
      subCategories: [],
    });
    setCurrPage(1);
  };

  return (
    <CollectionsContext.Provider
      value={{
        filters,
        setFilters,
        resetFilters,
        pagination: { currPage, setCurrPage },
      }}
    >
      {children}
    </CollectionsContext.Provider>
  );
};
