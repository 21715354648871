import clsx from "clsx";
import React from "react";
import { S3_BUCKET_URI } from "../../../lib/data";

const Gallary = () => {
  const GALLARY_BUCKET = S3_BUCKET_URI + "/static-images/gallery";

  return (
    <div className="flex flex-col">
      <h2 className="text-[40px] text-[#080808] font-semibold text-center mb-9 mt-14">
        Our Gallery
      </h2>
      {/* first row */}
      <div className="flex flex-col md:flex-row gap-4">
        <div className="grid gap-y-2 sm:gap-y-4 grid-cols-2 gap-x-2 sm:gap-x-4 w-full md:w-1/2">
          <GallaryImage label="WILSON ONYX" className="col-span-2">
            <img
              src={GALLARY_BUCKET + "/WILSON_ONYX.png"}
              alt="img"
              className="object-contain sm:object-cover w-full h-full sm:h-[329px]"
            />
          </GallaryImage>

          <GallaryImage label="CARRARA SILVER" className="col-span-1">
            <img
              src={GALLARY_BUCKET + "/CARRARA_SILVER.png"}
              alt="img"
              className="w-full h-full sm:h-[329px] object-contain sm:object-cover"
            />
          </GallaryImage>
          <GallaryImage label="VENETO GREY" className="col-span-1">
            <img
              src={GALLARY_BUCKET + "/VENETO_GREY.png"}
              alt="img"
              className="w-full h-full sm:h-[329px] object-contain sm:object-cover"
            />
          </GallaryImage>
        </div>
        <div className="w-full md:w-1/2">
          <GallaryImage label="MARVEL DREAM STONE" className="">
            <img
              src={GALLARY_BUCKET + "/MARVEL_DREAM_STONE.png"}
              alt="img"
              className="w-full h-full sm:h-[678px] object-contain sm:object-cover"
            />
          </GallaryImage>
        </div>
      </div>

      {/* second row */}
      <div className="flex flex-col md:flex-row gap-4 mt-4">
        <div className="grid grid-cols-2 gap-4 w-full md:w-1/2">
          <GallaryImage
            label="WALNUT BROWN"
            className="col-span-2 sm:col-span-1"
          >
            <img
              src={GALLARY_BUCKET + "/WALNUT_BROWN.png"}
              alt="img"
              className="h-[678px] w-full object-cover"
            />
          </GallaryImage>
          <div className="grid grid-cols-2 sm:flex sm:flex-col gap-x-4 col-span-2 sm:col-span-1 gap-y-4">
            <GallaryImage label="VELINO WOOD GOLD" className="col-span-1">
              <img
                src={GALLARY_BUCKET + "/VELINO_WOOD_GOLD.png"}
                alt="img"
                className="sm:w-full object-contain sm:object-cover h-full sm:h-[329px]"
              />
            </GallaryImage>
            <GallaryImage label="DESERT GREY" className="col-span-1">
              <img
                src={GALLARY_BUCKET + "/DESERT_GREY.png"}
                alt="img"
                className="sm:w-full object-contain sm:object-cover h-full sm:h-[329px]"
              />
            </GallaryImage>
          </div>
        </div>

        <div className="grid gap-y-4 grid-cols-2 gap-x-4 w-full md:w-1/2">
          <GallaryImage label="OROBICO GREY" className="col-span-1">
            <img
              src={GALLARY_BUCKET + "/OROBICO_GREY.png"}
              alt="img"
              className="w-full h-full sm:h-[329px] object-cover"
            />
          </GallaryImage>
          <GallaryImage label="ICEBERG BEIGE" className="col-span-1">
            <img
              src={GALLARY_BUCKET + "/ICEBERG_BEIGE.png"}
              alt="img"
              className="w-full h-full sm:h-[329px] object-cover"
            />
          </GallaryImage>
          <GallaryImage label="VICTORIAN PATH" className="col-span-2">
            <img
              src={GALLARY_BUCKET + "/VICTORIAN_PATH.png"}
              alt="img"
              className="object-cover w-full h-full sm:h-[329px]"
            />
          </GallaryImage>
        </div>
      </div>
    </div>
  );
};

const GallaryImage: React.FC<
  {
    children: React.ReactNode;
    label: string;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ children, label, className, ...props }) => {
  return (
    <div className={clsx("relative", className)} {...props}>
      <p className="absolute top-0 left-0 px-2 py-1 bg-[#F7F7F7] text-primary text-sm sm:text-base font-medium text-center uppercase">
        {label}
      </p>
      {children}
    </div>
  );
};

export default Gallary;
