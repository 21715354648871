import React from "react";
import Footer from "./footer";
import { DesktopNavbar, MobileNavbar } from "./navbar";

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="font-poppins text-[#373E51] relative w-[100%] bg-primary-light/40">
      <DesktopNavbar />
      <MobileNavbar />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
