import React, { useState } from "react";

const ReadMoreText: React.FC<{ text: string; maxLength: number }> = ({
  text,
  maxLength,
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const renderText = () => {
    if (text.length <= maxLength) {
      return text;
    }

    return expanded ? text : `${text.slice(0, maxLength)}...`;
  };

  return (
    <div>
      <span>{renderText()}</span>
      {text.length > maxLength && (
        <button onClick={toggleExpanded} className="text-blue-600 ml-1">
          {expanded ? "Read less" : "Read more"}
        </button>
      )}
    </div>
  );
};

export default ReadMoreText;
