import React from "react";
import {
  ADDRESS_DETAILS,
  CALL_US_CONTACT,
  SUPPORT_EMAIL_ADDRESS,
} from "../../../lib/data";
import ContactForm from "../components/contact-form";
import OfficeMap from "../components/office-map";

const ContactUsTemplate = () => {
  return (
    <div className="flex flex-col justify-start pt-6 md:pt-14 px-4 sm:px-5 pb-12 lg:mx-[5%]">
      <h1 className="font-semibold text-4xl sm:text-5xl lg:text-7xl">
        Contact Us
      </h1>
      <div className="grid grid-cols-2 gap-x-6 gap-y-4 mt-14 px-2">
        {/* left section */}
        <div className="flex flex-col col-span-2 sm:col-span-1 space-y-6 sm:space-y-8">
          <h4 className="font-bold text-2xl text-[#080808]">Get in touch</h4>
          <p className="text-base">
            Thank you for your interest in our rental venues and spaces! If you
            have any questions or would like to book a space, please don’t
            hesitate to contact us. Our team is dedicated to providing you with
            the highest level of service and support, and we are committed to
            working with you to make your event a success.
          </p>
          <div className="flex flex-col items-center sm:items-start space-y-4 sm:space-y-5">
            <div className="flex flex-col sm:flex-row sm:space-x-3 items-center">
              <img
                src="images/contact-us/email.svg"
                alt="email-icon"
                className="max-w-[80px] h-auto"
              />
              <div className="flex flex-col items-center sm:items-start justify-between py-1 space-y-2">
                <p className="font-semibold text-base uppercase">
                  Email Address
                </p>
                <p className="text-base">{SUPPORT_EMAIL_ADDRESS}</p>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row sm:space-x-3 items-center">
              <img
                src="images/contact-us/person.svg"
                alt="email-icon"
                className="max-w-[80px] h-auto"
              />
              <div className="flex flex-col items-center sm:items-start justify-between py-1 space-y-2">
                <p className="font-semibold text-base uppercase">
                  PHONE NUMBER
                </p>
                <p className="text-base">{CALL_US_CONTACT}</p>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row sm:space-x-3 items-center">
              <img
                src="images/contact-us/address.svg"
                alt="email-icon"
                className="max-w-[80px] h-auto"
              />
              <div className="flex flex-col items-center sm:items-start justify-between py-1 space-y-2">
                <p className="font-semibold text-base uppercase">OUR ADDRESS</p>
                <p className="text-base text-center sm:text-start">
                  {ADDRESS_DETAILS}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* right section */}
        <div className="col-span-2 sm:col-span-1">
          <ContactForm />
        </div>
      </div>

      <div className="mt-6 sm:mt-8 md:mt-12 mb-6">
        <OfficeMap />
      </div>
    </div>
  );
};

export default ContactUsTemplate;
