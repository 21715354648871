import React, { useContext, useState } from "react";
import CollectionFilters from "../components/filters";
import LoadingIndicator from "../../common/loading-indicator";
import ProductsList from "../../common/product-list/templates";
import CollectionsPagination from "../components/pagination";
import SideOverlay from "../../common/side-overlay";
import { NetworkStatus, gql, useQuery } from "@apollo/client";
import { CollectionsContext } from "../../../lib/context/collections-context";
import { COLLECTIONS_PAGINATION_LIMIT } from "../../../lib/data";
import { ProductType } from "../../../types";

const GET_PRODUCTS = gql`
  query GetProducts($filter: ProductFilter, $limit: Int, $page: Int) {
    getProducts(filter: $filter, limit: $limit, page: $page) {
      products {
        _id
        nameOfTheTile
        imagePath
        width
        length
      }
      totalPages
      totalCount
    }
  }
`;

type GetProductsQueryResponse = {
  getProducts: {
    products: ProductType[];
    totalPages: number;
    totalCount: number;
  };
};

export type GetProductsQueryVariables = {
  filter: {
    collections?: string[];
    colors?: string[];
    sizes?: string[];
    finishes?: string[];
    areasOfApplication?: string[];
    categories?: string[];
    patterns?: string[];
    series?: string[];
    subCategories?: string[];
  };
  limit: number;
  page: number;
};

const CollectionsTemplate = () => {
  const [enableFilters, setEnableFilters] = useState(false);

  const {
    filters,
    pagination: { currPage },
  } = useContext(CollectionsContext);

  const {
    data: products,
    refetch: refetchProducts,
    networkStatus,
  } = useQuery<GetProductsQueryResponse, GetProductsQueryVariables>(
    GET_PRODUCTS,
    {
      variables: {
        filter: filters,
        limit: COLLECTIONS_PAGINATION_LIMIT,
        page: currPage,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const loadingProducts =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables;

  return (
    <div className="min-h-[100vh] pt-6 md:pt-10 px-4 sm:px-5 pb-12">
      <div className="flex flex-col gap-y-5">
        <div className="flex flex-col relative">
          {/* <Breadcrumbs /> */}

          <div className="flex gap-x-4 mt-5 md:mt-12">
            <div className="hidden md:block md:basis-1/5">
              <CollectionFilters onFilterChange={refetchProducts} />
            </div>
            <div className="basis-full md:basis-4/5 min-h-[70vh]">
              {loadingProducts || !!!products ? (
                <LoadingIndicator />
              ) : (
                <ProductsList products={products.getProducts.products} />
              )}
            </div>
          </div>
          <div className="md:hidden w-full fixed bottom-0 z-10">
            <button
              type="button"
              className="justify-center bg-primary-light border-t inline-flex items-center gap-x-2 border-primary/20 -mx-4 sm:-mx-5 py-2 w-full text-base font-semibold text-primary shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
              onClick={() => setEnableFilters(true)}
            >
              <img
                src="/images/common/filter.png"
                alt="filter"
                className="w-4 h-4"
              />
              Filters
            </button>
          </div>
        </div>

        <div className="w-full">
          <CollectionsPagination
            onPageChange={refetchProducts}
            totalCount={products?.getProducts.totalCount ?? 0}
            totalPages={products?.getProducts.totalPages ?? 1}
          />
        </div>

        <SideOverlay
          title="Filters"
          isOpen={enableFilters}
          setIsOpen={setEnableFilters}
        >
          <div className="">
            <div className="px-4 pt-4">
              <CollectionFilters onFilterChange={refetchProducts} />
            </div>
          </div>
        </SideOverlay>
      </div>
    </div>
  );
};

export default CollectionsTemplate;
