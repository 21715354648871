import { useContext } from "react";
import { CollectionsContext } from "../../../lib/context/collections-context";
import { COLLECTIONS_PAGINATION_LIMIT } from "../../../lib/data";
import clsx from "clsx";
import { GetProductsQueryVariables } from "../templates";
import { scrollToTop } from "../../../lib/common";

const CollectionsPagination: React.FC<{
  totalPages: number;
  totalCount: number;
  onPageChange: (
    variables?: Partial<GetProductsQueryVariables> | undefined
  ) => void;
}> = ({ onPageChange, totalPages, totalCount }) => {
  const {
    filters,
    pagination: { currPage, setCurrPage },
  } = useContext(CollectionsContext);

  const handlePageChange = (newPg: number) => {
    setCurrPage(newPg);

    onPageChange({
      filter: filters,
      limit: COLLECTIONS_PAGINATION_LIMIT,
      page: newPg,
    });
    scrollToTop();
  };

  const handlePrevClick = () => {
    setCurrPage(currPage - 1);

    onPageChange({
      filter: filters,
      limit: COLLECTIONS_PAGINATION_LIMIT,
      page: currPage - 1,
    });
    scrollToTop();
  };

  const handleNextClick = () => {
    setCurrPage(currPage + 1);

    onPageChange({
      filter: filters,
      limit: COLLECTIONS_PAGINATION_LIMIT,
      page: currPage + 1,
    });
    scrollToTop();
  };

  return (
    <div className="flex items-center justify-between px-4 py-3 border-t border-primary-light sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          onClick={() => handlePrevClick()}
          disabled={currPage === 1}
          className="relative inline-flex disabled:bg-gray-300 disabled:cursor-not-allowed items-center rounded-md border border-gray-300 bg-primary-light px-4 py-2 text-sm font-medium text-gray-700 hover:bg-primary/20"
        >
          Previous
        </button>
        <button
          onClick={() => handleNextClick()}
          disabled={currPage === totalPages}
          className="relative ml-3 inline-flex disabled:bg-gray-300 disabled:cursor-not-allowed items-center rounded-md border border-gray-300 bg-primary-light px-4 py-2 text-sm font-medium text-gray-700 hover:bg-primary/20"
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing{" "}
            <span className="font-medium">
              {(currPage - 1) * COLLECTIONS_PAGINATION_LIMIT + 1}
            </span>{" "}
            to{" "}
            <span className="font-medium">
              {COLLECTIONS_PAGINATION_LIMIT * currPage}
            </span>{" "}
            of <span className="font-medium">{totalCount}</span> results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              onClick={() => handlePrevClick()}
              disabled={currPage === 1}
              className="relative inline-flex disabled:bg-gray-300 disabled:cursor-not-allowed items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-primary/20 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Previous</span>
              <img
                src="/images/common/left-arrow.svg"
                alt="left"
                className="h-5 w-5"
              />
            </button>
            {totalPages > 5
              ? renderMorePages(totalPages, currPage, handlePageChange)
              : renderLessPages(totalPages, currPage, handlePageChange)}
            <button
              onClick={() => handleNextClick()}
              disabled={currPage === totalPages}
              className="relative inline-flex disabled:bg-gray-300 disabled:cursor-not-allowed items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-primary/20 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Next</span>
              <img
                src="/images/common/right-arrow.svg"
                alt="right"
                className="h-5 w-5"
              />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

const renderMorePages = (
  totalPages: number,
  currPage: number,
  handleChange: (arg: number) => void
) => {
  const initialPages = () => {
    if (currPage < 3) {
      return Array.from({ length: 3 }, (_, index) => index + 1);
    }
    return Array.from({ length: 1 }, (_, index) => index + 1);
  };

  const middlePages = () => {
    if (currPage < 3 || currPage >= totalPages - 1) return null;

    return [currPage - 1, currPage, currPage + 1];
  };

  const endPages = () => {
    if (currPage < totalPages - 1) return [totalPages];
    return [totalPages - 2, totalPages - 1, totalPages];
  };

  const renderSlots = (arr: number[]) =>
    arr.map((pg) => (
      <p
        className={clsx(
          "relative inline-flex cursor-pointer items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-primary/20 focus:z-20 focus:outline-offset-0",
          {
            "bg-primary text-white hover:bg-primary": currPage === pg,
          }
        )}
        onClick={() => handleChange(pg)}
      >
        {pg}
      </p>
    ));

  return (
    <>
      {renderSlots(initialPages())}
      <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
        ...
      </span>
      {middlePages() && (
        <>
          {renderSlots(middlePages() ?? [0])}
          <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
            ...
          </span>
        </>
      )}
      {renderSlots(endPages())}
    </>
  );
};

const renderLessPages = (
  totalPages: number,
  currPage: number,
  handleChange: (arg: number) => void
) => {
  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

  return pages.map((pg) => (
    <p
      className={clsx(
        "relative inline-flex cursor-pointer items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-primary/20 focus:z-20 focus:outline-offset-0",
        {
          "bg-primary text-white hover:bg-primary": currPage === pg,
        }
      )}
      onClick={() => handleChange(pg)}
    >
      {pg}
    </p>
  ));
};

export default CollectionsPagination;
