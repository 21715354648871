import React from "react";
import VideoPlayer from "../../common/video-player";
import { ABOUT_US_VIDEO } from "../../../lib/data";

const AboutUsTemplate = () => {
  return (
    <div className="flex flex-col justify-start pt-6 md:pt-14 px-4 sm:px-5 pb-12 lg:mx-[3%]">
      <h1 className="font-semibold text-4xl sm:text-5xl lg:text-7xl">
        About Us
      </h1>
      <div className="grid grid-cols-2 sm:gap-x-7 gap-y-5 mt-7 sm:mt-11 md:mt-14 lg:mt-16">
        <div className="col-span-2 md:col-span-1 w-full max-w-[794px]">
          <img
            src="images/about-us/1.svg"
            alt="about us img"
            className="w-full h-auto"
          />
        </div>
        <div className="col-span-2 md:col-span-1 mt-7 sm:mt-10 md:mt-16 flex flex-col space-y-3 sm:space-y-4">
          <p className="text-[#3B3A3A] text-base font-semibold">
            Modern Innovation & Quality
          </p>
          <h4 className="font-medium text-3xl sm:text-[40px] leading-10 sm:leading-[60px]">
            We guarantee timeless elegance for your spaces
          </h4>
          <p className="text-base">
            Embark on a journey of elegance with Vansh Ceramics, a tile
            manufacturing pioneer since 2020. Our diverse product range features
            exquisite collections of Double Charge Tiles, Glazed Vitrified
            Tiles, Slabs & Planks, Full Body Tiles, Ceramic Wall & Floor Tiles,
            Ceramic Planks, and Digital Vitrified Parking Tiles and Planks. Each
            tile bears the hallmark of timeless craftsmanship, blending
            innovation and tradition to transform your spaces.
          </p>
        </div>
      </div>

      <div className="my-10 sm:my-14 md:my-20 w-full md:w-[70%] mx-auto border">
        <VideoPlayer videoSrc={ABOUT_US_VIDEO} />
      </div>

      <div className="-mx-4 sm:-mx-5 mt-16 sm:mt-24 lg:mt-0">
        <img src="images/about-us/2.svg" alt="img" className="w-full h-auto" />
      </div>
    </div>
  );
};

export default AboutUsTemplate;
