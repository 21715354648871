import React from "react";
import Layout from "../../modules/layout/templates";
import CatalogueTemplate from "../../modules/catalogue/templates";

const Catalogue = () => {
  return (
    <Layout>
      <CatalogueTemplate />
    </Layout>
  );
};

export default Catalogue;
