import { Dialog } from "@headlessui/react";

interface SideOverlayProps {
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
  title: string;
  children: React.ReactNode;
}

const SideOverlay: React.FC<SideOverlayProps> = ({
  isOpen,
  setIsOpen,
  title,
  children,
}) => {
  return (
    <Dialog
      as="div"
      className="relative z-50"
      open={isOpen}
      unmount={false}
      onClose={setIsOpen}
    >
      <div className="fixed inset-0 overflow-hidden bg-white">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
            <Dialog.Panel className="pointer-events-auto w-screen max-w-full bg-primary-light/40">
              <div className="flex h-full flex-col divide-y divide-gray-200  shadow-xl">
                <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-4">
                  <div className="flex items-center z-10 justify-between fixed top-0 w-full px-4 md:px-5 py-3 bg-primary drop-shadow-lg">
                    <Dialog.Title className="text-xl font-semibold text-white">
                      {title}
                    </Dialog.Title>
                    <div className="ms-3 flex items-center">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                        onClick={() => setIsOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <div className="h-5 w-5 bg-primary">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0,0,256,256"
                          >
                            <g
                              fill="#753a95"
                              fill-rule="nonzero"
                              stroke="none"
                              stroke-width="1"
                              stroke-linecap="butt"
                              stroke-linejoin="miter"
                              stroke-miterlimit="10"
                              stroke-dasharray=""
                              stroke-dashoffset="0"
                              font-family="none"
                              font-weight="none"
                              font-size="none"
                              text-anchor="none"
                              style={{
                                mixBlendMode: "normal",
                              }}
                            >
                              <path
                                d="M0,256v-256h256v256z"
                                id="bgRectangle"
                              ></path>
                            </g>
                            <g
                              fill="#ffffff"
                              fill-rule="nonzero"
                              stroke="none"
                              stroke-width="1"
                              stroke-linecap="butt"
                              stroke-linejoin="miter"
                              stroke-miterlimit="10"
                              stroke-dasharray=""
                              stroke-dashoffset="0"
                              font-family="none"
                              font-weight="none"
                              font-size="none"
                              text-anchor="none"
                              style={{
                                mixBlendMode: "normal",
                              }}
                            >
                              <g transform="scale(5.12,5.12)">
                                <path d="M7.71875,6.28125l-1.4375,1.4375l17.28125,17.28125l-17.28125,17.28125l1.4375,1.4375l17.28125,-17.28125l17.28125,17.28125l1.4375,-1.4375l-17.28125,-17.28125l17.28125,-17.28125l-1.4375,-1.4375l-17.28125,17.28125z"></path>
                              </g>
                            </g>
                          </svg>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="relative mt-5 flex-1">{children}</div>
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default SideOverlay;
