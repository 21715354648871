import React from "react";
import CraftmanshipValueCard from "../components/craftmanship-value-card";
import Finishes from "./finishes";
import Carousel from "./carousel";
import Gallary from "./gallary";
import VideoPlayer from "../../common/video-player";
import useCurrentWidth from "../../../lib/hooks/useCurrentWidth";
import {
  HOME_PAGE_CRAFTMANSHIP_PARA,
  VANSH_CERAMICS_DESKTOP_VIDEO,
  VANSH_CERAMICS_MOBILE_VIDEO,
  VANSH_CERAMICS_VIDEO_THUMBNAIL,
} from "../../../lib/data";
import ReadMoreText from "../../common/read-more-text";

const HomePageTemplate = () => {
  const currentWidth = useCurrentWidth();

  return (
    <div className="flex flex-col gap-y-4 sm:gap-y-14 px-4 sm:px-5">
      {/* Design, Surface and Expertise */}
      <div className="flex flex-col space-y-8 sm:flex-row sm:space-y-0 sm:space-x-5 sm:justify-between items-center">
        <div className="flex flex-col space-y-2 justify-center items-center">
          <img src="images/common/bulb.svg" alt="design" />
          <p className="font-semibold text-xl sm:text-2xl">Design</p>
          <p className="text-sm sm:text-base text-center font-medium">
            Our designs seamlessly transform spaces, turning ordinary spaces
            into extraordinary experiences.
          </p>
        </div>
        <div className="flex flex-col space-y-2 justify-center items-center">
          <img src="images/common/surfaces.svg" alt="surfaces" />
          <p className="font-semibold text-xl sm:text-2xl">Surfaces</p>
          <p className="text-sm sm:text-base text-center font-medium">
            We are committed to redefining surface standards and pushing
            boundaries for unparalleled quality and functionality.
          </p>
        </div>
        <div className="flex flex-col space-y-2 justify-center items-center">
          <img src="images/common/gear.svg" alt="expertise" />
          <p className="font-semibold text-xl sm:text-2xl">Expertise</p>
          <p className="text-sm sm:text-base text-center font-medium">
            Our expert craftsmen go the extra mile to guarantee the finest
            products reach every corner of the globe.
          </p>
        </div>
      </div>

      {/* Creaftmanship  */}
      <div className="my-14 flex sm:flex-row flex-col-reverse w-full space-y-4 sm:space-y-0 sm:space-x-4 sm:justify-between">
        <div className="sm:w-1/2 flex flex-col space-y-4 sm:space-y-6 md:space-y-8">
          <h3 className="text-3xl sm:text-[38px] font-medium mt-4 sm:mt-0 sm:leading-[57px]">
            Crafted with modern innovation and traditional craftsmanship
          </h3>
          <p className="text-base">
            <ReadMoreText text={HOME_PAGE_CRAFTMANSHIP_PARA} maxLength={706} />
          </p>
          <div className="grid sm:hidden md:grid grid-cols-3 gap-x-2 gap-y-6 md:gap-y-10">
            <CraftmanshipValueCard
              label="Waterproof Surface"
              src="images/homepage/waterproof-surface.svg"
            />
            <CraftmanshipValueCard
              label="Chemical Resistance"
              src="images/homepage/chemical-resistance.svg"
            />
            <CraftmanshipValueCard
              label="Thermal Shock Resistance"
              src="images/homepage/thermal-shock.svg"
              isLast
            />
            <CraftmanshipValueCard
              label="Resistance to Surface Abrasion"
              src="images/homepage/surface-abrasion.svg"
            />
            <CraftmanshipValueCard
              label="Resistance to Stain"
              src="images/homepage/stain-resistance.svg"
            />
            <CraftmanshipValueCard
              label="Respectful of the Environment"
              src="images/homepage/envronment-friendly.svg"
              isLast
            />
          </div>
        </div>
        <div className="sm:w-1/2 sm:max-w-[678px] relative transition-all duration-[3000] ease-in-out">
          <img
            src="images/homepage/craftmanship.svg"
            alt="img"
            className="w-full h-auto"
          />
        </div>
      </div>
      <div className="hidden sm:grid md:hidden grid-cols-3 gap-x-7 gap-y-6">
        <CraftmanshipValueCard
          label="Waterproof Surface"
          src="images/homepage/waterproof-surface.svg"
        />
        <CraftmanshipValueCard
          label="Chemical Resistance"
          src="images/homepage/chemical-resistance.svg"
        />
        <CraftmanshipValueCard
          label="Thermal Shock Resistance"
          src="images/homepage/thermal-shock.svg"
          isLast
        />
        <CraftmanshipValueCard
          label="Resistance to Surface Abrasion"
          src="images/homepage/surface-abrasion.svg"
        />
        <CraftmanshipValueCard
          label="Resistance to Stain"
          src="images/homepage/stain-resistance.svg"
        />
        <CraftmanshipValueCard
          label="Respectful of the Environment"
          src="images/homepage/envronment-friendly.svg"
          isLast
        />
      </div>
      <Carousel />
      <Finishes />
      <Gallary />
      <div className="mt-8 w-full md:w-[70%] mx-auto border">
        <VideoPlayer
          videoSrc={
            currentWidth < 768
              ? VANSH_CERAMICS_MOBILE_VIDEO
              : VANSH_CERAMICS_DESKTOP_VIDEO
          }
          thumbnailSrc={VANSH_CERAMICS_VIDEO_THUMBNAIL}
        />
      </div>
    </div>
  );
};

export default HomePageTemplate;
