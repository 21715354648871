import React, { useContext, useEffect } from "react";
import Breadcrumbs from "../../common/breadcrumbs";
import ProductImages from "../components/product-images";
import ProductInfo from "../components/product-info";
import ProductsList from "../../common/product-list/templates";
import { gql, useQuery } from "@apollo/client";
import LoadingIndicator from "../../common/loading-indicator";
import { useNavigate, useParams } from "react-router-dom";
import { scrollToTop } from "../../../lib/common";
import { CollectionsContext } from "../../../lib/context/collections-context";
import { ProductType } from "../../../types";
import GeneralDescriptionTable from "../components/general-description-table";
import Collapsible from "../../common/collapsible";

const GET_PRODUCT_DETAILS_QUERY = gql`
  query GetProductById($id: ID!) {
    getProductById(_id: $id) {
      _id
      referenceId
      nameOfTheTile
      shortDescription
      imagePath
      fullDescription
      collections
      colorFamily
      showProductAvailability
      haSKUCode
      category
      subCategory
      series
      homeAlankarCode
      areaOfApplication
      finish
      pattern
      width
      thickness
      length
      numberOfPiecesPerBox
      coverage
      weightPerBox

      deviationInLengthAndWidth
      deviationInThickness
      straightnessOfSides
      diagonalVariationOrRectangularity
      surfaceFlatness
      surfaceQuality
      waterAbsorption
      MOR_ModulusOfRupture
      breakingStrength
      abrasionResistanceUnglazedTiles
      MOHsScratchHardness
      impactResistanceCOR
      crazingResistance
      thermalShockResistance
      frostResistance
      linearThermalExpansion
      resistanceToHouseholdChemical
      resistanceToStain
      bulkDensity
      moistureExpansion

      relatedProducts {
        _id
        nameOfTheTile
        imagePath
        width
        length
      }
    }
  }
`;

type ProductDetailsQueryResponse = {
  getProductById: ProductType;
};

type ProductDetailsQueryVariables = {
  id: string;
};

const ProductDetailsTemplate = () => {
  const { productId = "" } = useParams<{ productId: string }>();
  const { setFilters } = useContext(CollectionsContext);
  const navigate = useNavigate();

  const { data: product, loading: loadingProduct } = useQuery<
    ProductDetailsQueryResponse,
    ProductDetailsQueryVariables
  >(GET_PRODUCT_DETAILS_QUERY, {
    variables: {
      id: productId,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    scrollToTop();
  }, [productId]);

  const breadcrumbsPages = [
    {
      name: product?.getProductById.collections ?? "",
      onClick: () => {
        setFilters({
          collections: [
            (product?.getProductById.collections ?? "").toUpperCase(),
          ],
          colors: [],
          finishes: [],
          sizes: [],
          areasOfApplication: [],
          categories: [],
          patterns: [],
          series: [],
          subCategories: [],
        });
        navigate("/collections");
      },
      current: false,
    },
    {
      name: product?.getProductById.nameOfTheTile ?? "",
      onClick: () => {},
      current: true,
    },
  ];

  const specificationsData: { title: string; content: string | undefined }[] = [
    {
      title: "Deviation in length and width",
      content: product?.getProductById.deviationInLengthAndWidth,
    },
    {
      title: "Deviation in thickness",
      content: product?.getProductById.deviationInThickness,
    },
    {
      title: "Straightness of sides",
      content: product?.getProductById.straightnessOfSides,
    },
    {
      title: "Diagonal variation or rectangularity",
      content: product?.getProductById.diagonalVariationOrRectangularity,
    },
    {
      title: "Surface flatness",
      content: product?.getProductById.surfaceFlatness,
    },
    {
      title: "Surface quality",
      content: product?.getProductById.surfaceQuality,
    },
    {
      title: "Water absorption",
      content: product?.getProductById.waterAbsorption,
    },
    {
      title: "Modulus of rupture (MOR)",
      content: product?.getProductById.MOR_ModulusOfRupture,
    },
    {
      title: "Breaking strength",
      content: product?.getProductById.breakingStrength,
    },
    {
      title: "Abrasion resistance (Unglazed Tiles)",
      content: product?.getProductById.abrasionResistanceUnglazedTiles,
    },
    {
      title: "MOH's scratch hardness",
      content: product?.getProductById.MOHsScratchHardness,
    },
    {
      title: "Bulk density",
      content: product?.getProductById.bulkDensity,
    },
    {
      title: "Moisture expansion",
      content: product?.getProductById.moistureExpansion,
    },
    {
      title: "Impact resistance (COR)",
      content: product?.getProductById.impactResistanceCOR,
    },
    {
      title: "Crazing resistance",
      content: product?.getProductById.crazingResistance,
    },
    {
      title: "Thermal shock resistance",
      content: product?.getProductById.thermalShockResistance,
    },
    {
      title: "Frost resistance",
      content: product?.getProductById.frostResistance,
    },
    {
      title: "Linear thermal expansion",
      content: product?.getProductById.linearThermalExpansion,
    },
    {
      title: "Resistance to household chemical",
      content: product?.getProductById.resistanceToHouseholdChemical,
    },
    {
      title: "Resistance to stain",
      content: product?.getProductById.resistanceToStain,
    },
  ];

  return (
    <div className="min-h-[100vh] pt-6 md:pt-14 px-4 sm:px-5 pb-12 lg:mx-[3%]">
      {loadingProduct || !!!product ? (
        <LoadingIndicator />
      ) : (
        <>
          <Breadcrumbs pages={breadcrumbsPages} />
          {/* photos and info */}
          <div className="flex flex-col md:flex-row md:gap-x-5 lg:gap-x-7 gap-y-4 md:gap-y-0 mt-5">
            <div className="md:basis-[60%] lg:basis-[50%]">
              <ProductImages images={[product.getProductById.imagePath]} />
            </div>
            <ProductInfo product={product.getProductById} />
          </div>

          <div className="w-full mt-6 sm:mt-10">
            <Collapsible title="Product Specifications" open>
              <GeneralDescriptionTable data={specificationsData} />
            </Collapsible>
          </div>

          {/* related products */}
          <div className="flex flex-col space-y-4 sm:space-y-5 mt-6 sm:mt-10">
            <h4 className="text-xl sm:text-2xl font-semibold">
              Related Products
            </h4>
            <ProductsList products={product.getProductById.relatedProducts} />
          </div>
        </>
      )}
    </div>
  );
};

export default ProductDetailsTemplate;
