import clsx from "clsx";
import React, { ReactNode, useState } from "react";

interface IProps {
  open?: boolean;
  title: string | ReactNode;
  children: ReactNode;
}

const Collapsible: React.FC<IProps> = ({ open, children, title }) => {
  const [isOpen, setIsOpen] = useState(open);

  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className="">
      <div
        className={clsx(
          "flex bg-primary-light drop-shadow-lg justify-between py-3 px-6 cursor-pointer rounded-t-[4px]",
          {
            "rounded-b-[4px]": !isOpen,
          }
        )}
        onClick={() => handleFilterOpening()}
      >
        <div>
          <h3 className="pr-2 text-justify text-base sm:text-xl font-semibold text-primary">
            {title}
          </h3>
        </div>
        <div className="flex items-center justify-center">
          <div
            className={clsx("h-4 w-4 transition-all transform", {
              "-rotate-180": !isOpen,
            })}
          >
            <img src="/images/common/down.svg" alt="close" />
          </div>
        </div>
      </div>

      {isOpen && <div>{children}</div>}
    </div>
  );
};

export default Collapsible;
