import React from "react";
import CatalogueCard from "../components/catalogue-card";

const CatalogueList = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-2 sm:gap-x-3 md:gap-x-4 gap-y-4 sm:gap-y-5">
      <CatalogueCard />
    </div>
  );
};

export default CatalogueList;
