import React, { useState } from "react";
import TextField from "../../common/inputs/text-field";
import TextAreaField from "../../common/inputs/text-area";
import { gql, useMutation } from "@apollo/client";
import { removeEmptyFields } from "../../../lib/common";

const CONTACT_US_MUTATION = gql`
  mutation CreateContactus($input: ContactusInput!) {
    createContactus(input: $input) {
      _id
      referenceId
      name
    }
  }
`;

type CreateContactUsMutationResponse = {
  createContactus: any;
};

type CreateContactUsMutationVariables = {
  input: {
    name: string;
    phone: string;
    email: string;
    company?: string;
    message?: string;
    emailTrackingConsent: boolean;
  };
};

const ContactForm = () => {
  const [formState, setFormState] = useState<Record<string, any>>({});
  const [successMsg, setSuccessMsg] = useState(false);

  const [contactUs, { loading }] = useMutation<
    CreateContactUsMutationResponse,
    CreateContactUsMutationVariables
  >(CONTACT_US_MUTATION);

  const handleChange = (fieldName: string, value: any) => {
    if (successMsg) setSuccessMsg(false);

    setFormState((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const refinedFields = removeEmptyFields(formState);

    contactUs({
      variables: {
        input: refinedFields,
      },
      onCompleted: (_) => setSuccessMsg(true),
    });
  };

  return (
    <form className="grid grid-cols-2 gap-4" onSubmit={handleSubmit}>
      <div className="col-span-2 sm:col-span-1">
        <TextField
          label="Your Name :"
          placeholder="Name"
          type="text"
          value={formState.name ?? ""}
          onChange={(e) => handleChange("name", e.target.value)}
          required
        />
      </div>
      <div className="col-span-2 sm:col-span-1">
        <TextField
          label="Your Phone :"
          placeholder="Phone"
          type="number"
          required
          value={formState.phone ?? ""}
          onChange={(e) => handleChange("phone", e.target.value)}
        />
      </div>
      <div className="col-span-2 sm:col-span-1">
        <TextField
          label="Your E-mail :"
          placeholder="Email"
          type="email"
          value={formState.email ?? ""}
          onChange={(e) => handleChange("email", e.target.value)}
          required
        />
      </div>
      <div className="col-span-2 sm:col-span-1">
        <TextField
          label="Company :"
          placeholder="Company"
          type="text"
          value={formState.company ?? ""}
          onChange={(e) => handleChange("company", e.target.value)}
        />
      </div>
      <div className="col-span-2">
        <TextAreaField
          label="Message :"
          placeholder="Message"
          rows={3}
          value={formState.message ?? ""}
          onChange={(e) => handleChange("message", e.target.value)}
        />
      </div>
      <div className="col-span-2 flex space-x-3 items-center">
        <input
          id="user-consent"
          name="user-consent"
          type="checkbox"
          className="h-5 w-5 rounded border-gray-300 outline-none accent-primary"
          required
          value={formState.emailTrackingConsent ?? false}
          onChange={(e) =>
            handleChange("emailTrackingConsent", e.target.checked)
          }
        />
        <label htmlFor="user-consent" className="text-base cursor-pointer">
          I consent to email tracking for a better experience
        </label>
      </div>
      {successMsg && (
        <p className="col-span-2 text-xs text-green-400 font-medium">
          Your Response has been received.
        </p>
      )}
      <button
        type="submit"
        disabled={loading}
        className="col-span-2 w-full sm:w-1/2 md:w-1/3 lg:w-1/6 px-3 py-3 text-[15px] text-white bg-primary disabled:bg-gray-400 leading-[18px] text-center rounded-[4px] outline-none"
      >
        Get In Touch
      </button>
    </form>
  );
};

export default ContactForm;
